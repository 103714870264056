@import "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap";
:root {
  --main-font: "Agdasima", sans-serif;
  --custom-font: "Orbitron", sans-serif;
  --main-bg-color: #030511;
  --text-color: #e3ddde;
  --mainTitleColor: #0086ff;
  --highlight-color: #cdfff1;
  --discord-bg-color: #7289da;
  --discord-text-color: #fff;
  --shadow-color: #000000b3;
  --gradient-bg-bottom: linear-gradient(to bottom, transparent, black);
  --gradient-bg-top: linear-gradient(to top, transparent, black);
  --white-color: #fff;
  --wireframe-color: #e3ddde;
  --paddingSide: 0 0vw;
}

body {
  background-color: var(--main-bg-color);
  font-family: var(--main-font);
  color: var(--text-color);
  margin: 0;
  overflow-x: hidden;
}

.overlay {
  pointer-events: none;
  z-index: 1;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: calc(80px + 2vh);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.textoverlay {
  width: 100%;
  position: relative;
}

.discord-button, #scroll-to-bottom, #scroll-to-top {
  pointer-events: auto;
}

.TopContainer h1 {
  color: var(--mainTitleColor);
  font-family: var(--custom-font);
  opacity: .9;
  margin: 1vh 0;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.spacer {
  align-self: stretch;
  padding: 0;
}

.overlay p {
  text-shadow: 2px 2px 4px var(--shadow-color);
  margin-top: 10px;
  font-size: 1.5em;
}

.discord-button {
  background-color: var(--discord-bg-color);
  color: var(--discord-text-color);
  box-shadow: 0 4px 8px var(--shadow-color);
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  text-decoration: none;
}

.aboutUs {
  padding: var(--paddingSide, 0 8vw);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  margin-top: -30px;
  display: flex;
}

.aboutUs h1 {
  color: var(--mainTitleColor);
  font-family: var(--custom-font);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blurryRoundedBox {
  -webkit-backdrop-filter: blur(9px);
  background: #80808080;
  border: 2px solid #b1b1b180;
  border-radius: 30px;
  flex-wrap: wrap;
  flex: 1 0 0;
  place-content: center;
  align-items: center;
  gap: 48px 24px;
  height: 216px;
  margin-top: 0;
  padding: 30px 48px;
  display: flex;
  box-shadow: 0 0 20px #dddddd40;
}

.aboutUs p {
  color: var(--White, #fff);
  font-family: var(--main-font);
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aboutUs span {
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.TopContainer, .BottomContainer {
  text-shadow: 2px 2px 4px var(--shadow-color);
  flex-direction: column;
  padding-top: 2vh;
  font-size: 1.5em;
  display: flex;
}

.TopContainer p {
  text-shadow: 2px 2px 4px var(--shadow-color);
  width: 800px;
  font-size: 1.5em;
}

.TopContainer h2 {
  color: var(--White, #fff);
  font-family: var(--main-font, "Roboto Condensed");
  margin-bottom: -20px;
  padding: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BottomContainer {
  align-items: center;
  padding-bottom: 129.6px;
  bottom: 0;
}

.BottomContainer a {
  color: var(--White, #fff);
  justify-content: center;
  align-items: center;
  width: 30vw;
  text-decoration: none;
  display: flex;
}

.BottomButton, .TopButton {
  color: var(--white-color);
  cursor: pointer;
  pointer-events: auto;
  opacity: 1;
  border: none;
  border-radius: 5px;
  width: 100vw;
  height: 86.4px;
  padding: 10px 20px;
  font-size: 1.5em;
  position: absolute;
}

.BottomButton p, .TopButton p {
  opacity: 0;
}

.BottomButton {
  background: #08f0;
  bottom: 0;
}

.TopButton {
  z-index: 5;
  background: #08f0;
  top: 0;
}

#mainContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
}

#top-section, #bottom-section {
  width: 100%;
  transition: transform .5s ease-in-out;
  position: absolute;
}

#top-section {
  position-area: center;
  width: max(82vw, 1300px);
  top: 0;
  overflow: hidden;
  transform: translateY(0);
}

#bottom-section {
  overscroll-behavior: none;
  height: auto;
  overflow-x: hidden;
  transform: translateY(100%);
}

.bottomSectionContainer {
  flex-direction: column;
  align-items: center;
  gap: 80px;
  width: max(67vw, 700px);
  display: flex;
}

.SocialCardContainer {
  justify-content: center;
  align-self: stretch;
  margin: auto 8vw;
  display: flex;
}

@media (width <= 1200px) {
  .bottomSectionContainer {
    height: auto;
    font-size: .8em;
  }
}

.gamerPlatform {
  padding: var(--paddingSide, 0 8vw);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 48px;
  display: flex;
}

.gamerPlatform span {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  display: flex;
}

.gamerPlatform p {
  color: var(--White, #fff);
  text-align: right;
  width: max(30vw, 500px);
}

.heading1 {
  font-family: var(--custom-font);
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraph1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraph2 {
  font-family: var(--main-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gamerPlatform h1 {
  color: #0086ff;
  width: max(30vw, 700px);
}

.gamerPlatformDetails {
  justify-content: center;
  align-items: flex-start;
  gap: 72px;
  width: 100%;
  display: flex;
}

.imageTextContainer {
  height: 691.2px;
  position: relative;
  overflow: hidden;
}

.one-third {
  flex: 0 0 44%;
}

.two-thirds {
  flex: 0 0 64%;
}

.vision img {
  content: url("cuboHomePage.5e3d3a5a.webp");
  position: absolute;
  bottom: -100px;
  right: -80px;
  width: 320px !important;
  height: 320px !important;
}

.mission img {
  content: url("spiraleHomePage.70d38299.webp");
  position: absolute;
  bottom: -100px;
  right: -80px;
  width: 320px !important;
  height: 320px !important;
}

.imageTextContainer img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.gamerImgText {
  color: var(--White, #fff);
  text-align: left;
  width: 80%;
  padding: 0 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gamerImgText h2 {
  color: #fff;
  font-size: 30px;
  font-family: var(--main-font);
  text-align: left;
  z-index: 1;
  background-color: #12121200;
  border-radius: 50px;
  padding: 20px 50px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.gamerImgText h2:before {
  content: "";
  -webkit-mask-composite: destination-out;
  z-index: -1;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(to right, #0086ff, #ffa03c);
  border-radius: 50px;
  padding: 2px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.boxedTitle {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.boxedTitle h2 {
  color: #fff;
  font-size: 24px;
  font-family: var(--main-font);
  text-align: center;
  z-index: 1;
  background-color: #12121200;
  border-radius: 50px;
  margin: 10px 0;
  padding: 20px 50px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.boxedTitle h2:before {
  content: "";
  -webkit-mask-composite: destination-out;
  z-index: -1;
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  background: linear-gradient(to right, #0086ff, #ffa03c);
  border-radius: 50px;
  padding: 2px;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

.boxedTitle p {
  color: #fff;
  font-size: 30px;
  font-family: var(--main-font);
  border-radius: 50px;
}

.gamerImgText p {
  color: var(--White, #fff);
  text-align: left;
}

.heading4 {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.heading2 {
  font-family: var(--custom-font);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gamerImgText p {
  color: var(--White, #fff);
  text-align: left;
  width: 100%;
}

.socialLinks {
  z-index: 3;
  pointer-events: auto;
  justify-content: space-between;
  gap: 1vw;
  margin-top: -43.2px;
  padding-top: 0;
  display: flex;
  position: relative;
}

.socialLinks p {
  text-align: center;
  place-content: center;
  align-self: stretch;
}

.socialLinks a {
  z-index: 3;
  cursor: pointer;
}

.RoadMap {
  padding: var(--paddingSide, 0 8vw);
  align-self: center;
  width: 65vw;
}

.RoadMap h1 {
  text-align: center;
  margin-top: 75.6px;
  margin-bottom: 0;
  padding-bottom: 2vh;
}

.RoadMap span {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.aboutUsSpacer {
  height: 540px;
}

.RoadMapSpacer {
  height: 864px;
}

.partners {
  padding: var(--paddingSide, 0 8vw);
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.partnersSpacer {
  height: 388.8px;
}

.circle-container {
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 150px;
  display: flex;
}

.circle {
  color: #fff;
  aspect-ratio: 1;
  background: linear-gradient(135deg, #0086ff 29.83%, #ffa03c 100%);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.description {
  text-align: center;
  color: #fff;
  margin-top: 10px;
  font-size: 24px;
}

.line-container {
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: 20px;
  padding-bottom: 100px;
  display: flex;
  position: relative;
}

.gradient-line {
  background: linear-gradient(135deg, #0086ff 29.83%, #ffa03c 100%);
  flex-grow: 1;
  height: 5px;
}

.arrow {
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-left: 15px solid #ffa03c;
  width: 0;
  height: 0;
  margin-left: -5px;
}

.FooterContainer {
  height: 648px;
}

canvas {
  z-index: -1;
  transform-origin: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.endpager {
  padding: 0 10vw 4vh;
}

html.lenis body {
  height: auto;
}

.label {
  backdrop-filter: blur();
  cursor: pointer;
  opacity: 1;
  text-align: center;
  background: #80808000;
  border-radius: 10%;
  width: auto;
  min-width: 10px;
  height: auto;
  padding: 10px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.label h1, .label h2, .label p {
  opacity: 1;
  color: #fff;
  margin: 0;
  padding: 0;
  transition: opacity .3s;
}

.label p {
  opacity: 0;
}

.label:hover, .hovered {
  opacity: 1;
  backdrop-filter: blur(8px);
  background: #80808080;
  border-radius: 15px;
  padding: 20px;
}

.label:hover h1, .label:hover h2, .label:hover p, .hovered p {
  opacity: 1;
}

.portrait-container {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.portrait-circle {
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px #0000004d;
}

.mission p {
  width: 80%;
}

.vision p {
  width: 97%;
}

#loadingOverlay {
  z-index: 1000;
  background: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#loaderText {
  color: #fff;
  margin-bottom: 20px;
  font-size: 2em;
}

#loaderSpinner {
  border: 5px solid #ffffff4d;
  border-top-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 1s linear infinite spin;
}

.vision {
  background: var(--Custom-bg0, linear-gradient(135deg, #0a0f2940 0%, #0a0f2921 40%, #9f01ad21 72%, #9f01ad40 100%), #030511);
  border: 2px solid #ffffff40;
  border-radius: 36px;
  width: 906px;
  height: 472px;
  margin: 0;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 -4px 24px #0a0f2980, -5px 5px 24px #ffffff0d;
}

.mission {
  background: var(--Custom-bg0, linear-gradient(135deg, #0a0f2940 0%, #0a0f2921 40%, #9f01ad21 72%, #9f01ad40 100%), #030511);
  border: 2px solid #ffffff40;
  border-radius: 36px;
  width: 442px;
  height: 472px;
  margin: 0;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 -4px 24px #0a0f2980, -5px 5px 24px #ffffff0d;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (width <= 1600px) {
  #top-section {
    width: 100%;
    padding: calc(80px + 2vh) 9vw 0;
    transition: transform .5s ease-in-out;
    position: absolute;
  }
}

.spiral-frame {
  content: url("Spiral_Frame.6fa8808d.webp");
}

.cube-frame {
  content: url("Cube_frame.0a1ee385.webp");
}

@media (width <= 800px) {
  .vision {
    width: 89vw;
    height: 620px;
  }

  .vision img {
    bottom: -128px;
    right: 0;
  }

  .two-thirds {
    flex: none;
  }

  .mission {
    width: 89vw;
    height: 500px !important;
  }

  .mission img {
    bottom: -128px;
    right: 0;
  }

  .one-third {
    flex: none;
  }

  .TopContainer h1 {
    font-size: 13vw;
  }

  .TopContainer h2 {
    font-size: 6vw;
  }

  .TopContainer p {
    width: 70vw;
    font-size: 6vw;
  }

  .bottomSectionContainer {
    width: 90vw;
    max-width: 650px;
    height: auto;
  }

  .gamerPlatform, .gamerPlatform span h1, .gamerPlatform span p {
    width: 100%;
  }

  .gamerPlatform span, .gamerPlatformDetails {
    flex-direction: column;
  }

  .gamerPlatform h2 {
    font-size: 5vw;
  }

  .heading1 {
    font-size: 10vw;
  }

  .heading4, .paragraph1 {
    font-size: 5vw;
  }

  .gamerImgText {
    width: 50vw;
    height: auto;
    margin: 7% 0;
    left: 40%;
    padding: 0 !important;
  }

  .gamerImgText h2 {
    padding: 20px 30px;
  }

  .gamerImgText p {
    width: 70vw;
    padding: 0;
    font-size: min(6vw, 28px);
  }

  .aboutUs {
    height: 600px;
  }

  .aboutUs span {
    flex-direction: column;
    width: 100%;
  }

  .aboutUs p {
    width: 70vw;
    font-size: 6vw;
  }

  #roadmapTitles {
    opacity: 0;
  }

  .blurryRoundedBox {
    width: 72%;
    height: auto;
  }

  .spiral-frame {
    content: url("Spiral_frame_mobile.c8cd3332.webp");
  }

  .cube-frame {
    content: url("Cube_frame_mobile.44e55172.webp");
  }

  .socialLinks p {
    width: 90vw;
  }

  .vision {
    height: 610px;
    margin: 0;
  }

  .mission {
    height: 510px;
    margin: 0;
  }

  .RoadMap h1 {
    font-size: 11vw;
  }

  .RoadMap h2 {
    padding: 15px 30px;
  }

  .RoadMap span {
    flex-direction: column;
    gap: 830px;
    padding-top: 10px;
    padding-right: 223px;
  }

  .aboutUsSpacer {
    height: 1324px;
  }

  .RoadMapSpacer {
    height: 313px;
  }

  .partners h1 {
    font-size: 10vw;
  }

  .FooterContainer {
    height: 800px;
  }

  .BottomButton {
    opacity: 0;
  }

  .line-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 950px;
    display: flex;
    position: relative;
  }

  .circle-container {
    flex-direction: row-reverse;
    width: 250px;
    height: 150px;
    display: flex;
  }

  .gradient-line {
    background: linear-gradient(#0086ff 29.83%, #ffa03c 100%);
    flex-grow: 1;
    width: 5px;
    height: 1000px;
  }

  .description {
    padding-right: 36px;
  }

  .arrow {
    border-bottom: 15px solid #ffa03c;
    border-left: 10px solid #0000;
    border-right: 10px solid #0000;
    width: 0;
    height: 0;
    margin-left: 0;
  }

  .partnersSpacer {
    height: 830px;
  }
}

@media (width <= 380px) {
  .aboutUsSpacer {
    height: 1144px;
  }

  .RoadMapSpacer {
    height: 600px;
  }
}
/*# sourceMappingURL=index.2c249df7.css.map */
