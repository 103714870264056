@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

/* Define CSS Variables */
:root {
  /* Fonts */
  --main-font: "Agdasima", sans-serif;
  --custom-font: "Orbitron", sans-serif;

  /* Colors */
  --main-bg-color: #030511;
  --text-color: #E3DDDE;
  --mainTitleColor: #0086FF;
  --highlight-color: #cdfff1;
  --discord-bg-color: #7289da;
  --discord-text-color: #ffffff;
  --shadow-color: rgba(0, 0, 0, 0.7);
  --gradient-bg-bottom: linear-gradient(to bottom, transparent, black);
  --gradient-bg-top: linear-gradient(to top, transparent, black);
  --white-color: rgba(255, 255, 255, 1);
  --wireframe-color: #E3DDDE;
  --paddingSide: 0 0vw;
  /* Fallback color */
}

/* General Styles */
body {
  background-color: var(--main-bg-color);
  margin: 0;
  font-family: var(--main-font);
  color: var(--text-color);
  overflow-x: hidden;
}

/* Overlay Container */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  /* Allows interaction with underlying Three.js scene */
  z-index: 1;
  /* Ensures the overlay is on top of the Three.js canvas */
  padding-top: calc(80px + 2vh);
  /* Navbar height + padding */
  box-sizing: border-box;
}

.textoverlay {
  position: relative;
  width: 100%;
}

/* Enable pointer events on buttons */
.discord-button,
#scroll-to-bottom,
#scroll-to-top {
  pointer-events: auto;
}

/* Main Title Styling */
.TopContainer h1 {
  color: var(--mainTitleColor);
  font-family: var(--custom-font);
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1vh 0;
  opacity: 0.9;
}


.spacer {
  padding: 0 0vw;
  align-self: stretch;
}

/* Subtitle Styling */
.overlay p {
  font-size: 1.5em;
  margin-top: 10px;
  text-shadow: 2px 2px 4px var(--shadow-color);
}

/* Discord Button Styling */
.discord-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--discord-bg-color);
  color: var(--discord-text-color);
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.aboutUs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: var(--paddingSide, 0 8vw);
  margin-top: -30px;
}

.aboutUs h1 {
  color: var(--mainTitleColor);
  font-family: var(--custom-font);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blurryRoundedBox {
  background: #80808080;
  border-radius: 30px;
  display: flex;
  height: 216px;
  /* 20% of 1080px */
  padding: 30px 48px;
  margin-top: 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 48px 24px;
  flex: 1 0 0;
  flex-wrap: wrap;
  border: 2px solid rgb(177, 177, 177)80;
  box-shadow: 0px 0px 20px rgba(221, 221, 221, 0.25);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  /* Added vendor prefix */

}

.aboutUs p {
  color: var(--White, #FFF);
  font-family: var(--main-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.aboutUs span {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.TopContainer,
.BottomContainer {
  display: flex;
  font-size: 1.5em;
  text-shadow: 2px 2px 4px var(--shadow-color);
  flex-direction: column;
  padding-top: 2vh;
}

.TopContainer p {
  font-size: 1.5em;
  text-shadow: 2px 2px 4px var(--shadow-color);
  width: 800px;

}

.TopContainer h2 {
  color: var(--White, #FFF);
  /* Heading 4 */
  font-family: var(--main-font, "Roboto Condensed");
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: -20px;
  padding: 0;
}

.BottomContainer {
  bottom: 0;
  padding-bottom: 129.6px;
  /* 12vh of 1080px */
  align-items: center;
}

.BottomContainer a {
  color: var(--White, #FFF);
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30vw;

}

.BottomButton,
.TopButton {
  position: absolute;
  color: var(--white-color);
  /* Ensure text is visible on the gradient */
  border: none;
  padding: 10px 20px;
  width: 100vw;
  height: 86.4px;
  /* 8vh of 1080px */
  border-radius: 5px;
  cursor: pointer;
  pointer-events: auto;
  font-size: 1.5em;
  opacity: 1;
}

.BottomButton p,
.TopButton p {
  opacity: 0;
}

.BottomButton {
  background: #0088ff00;
  bottom: 0;
}

.TopButton {
  background: #0088ff00;
  top: 0;
  z-index: 5;
}

/* Main Container */
#mainContainer {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* Only the viewport height */
}

/* Sections */
#top-section,
#bottom-section {
  position: absolute;
  width: 100%;

  transition: transform 0.5s ease-in-out;
}

#top-section {
  top: 0;
  transform: translateY(0);
  overflow: hidden;
  width: max(82vw, 1300px);
  position-area: center;
  /* Initially in view */
}


#bottom-section {
  height: auto;
  transform: translateY(100%);
  /* Initially out of view */
  overflow-x: hidden;
  overscroll-behavior: none;
  /* Prevents bounce at scroll limits */

}

.bottomSectionContainer {
  display: flex;
  width: max(67vw, 700px);
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

.SocialCardContainer {
  align-self: stretch;
  display: flex;
  justify-content: center;
  margin: auto 8vw;
}

@media (max-width: 1200px) {
  .bottomSectionContainer {
    font-size: 0.8em;
    height: auto;
  }
}

.gamerPlatform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  padding: var(--paddingSide, 0 8vw);

}

.gamerPlatform span {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.gamerPlatform p {
  color: var(--White, #FFF);
  text-align: right;
  width: max(30vw, 500px);

}

.heading1 {
  /* Heading 1 */
  font-family: var(--custom-font);
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraph1 {
  /* Paragraph 1 */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paragraph2 {
  font-family: var(--main-font);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gamerPlatform h1 {
  color: #0086FF;
  width: max(30vw, 700px);
  /* Heading 1 */
}

.gamerPlatformDetails {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* Align items at the top */
  width: 100%;
  gap: 72px;
}

.imageTextContainer {
  position: relative;
  height: 691.2px;
  /* 64% of 1080px */
  /* Set a fixed height for uniformity */
  overflow: hidden;
}

.one-third {
  flex: 0 0 44%;
  /* Container takes up one-third of the width */
}

.two-thirds {
  flex: 0 0 64%;
  /* Container takes up two-thirds of the width */
}

.vision img {
  content: url('../assets/img/cuboHomePage.webp');
  position: absolute;
  right: -80px;
  bottom: -100px;
  width: 320px !important;
  height: 320px !important;
}

.mission img {
  content: url('../assets/img/spiraleHomePage.webp');
  position: absolute;
  right: -80px;
  bottom: -100px;
  width: 320px !important;
  height: 320px !important;
}

.imageTextContainer img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* Fill the container without distortion */
}

.gamerImgText {
  position: absolute;
  top: 35%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust position */
  color: var(--White, #FFF);
  text-align: left;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  width: 80%;
  /* Adjust as needed */
  padding: 0 10px;
  /* Optional: Add padding for better text spacing */
}

.gamerImgText h2 {
  position: relative;
  display: inline-block;
  padding: 20px 50px;
  font-size: 30px;
  color: white;
  font-family: var(--main-font);
  border-radius: 50px;
  background-color: #12121200;
  text-align: left;
  z-index: 1;
  overflow: hidden;
}

.gamerImgText h2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  padding: 2px;
  background: linear-gradient(to right, #0086FF, #FFA03C);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
}

.boxedTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.boxedTitle h2 {
  position: relative;
  display: inline-block;
  padding: 20px 50px;
  font-size: 24px;
  color: white;
  font-family: var(--main-font);
  border-radius: 50px;
  background-color: #12121200;
  /* Change from transparent to a slightly visible background */
  text-align: center;
  z-index: 1;
  overflow: hidden;
  margin: 10px 0;
}

.boxedTitle h2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  padding: 2px;
  background: linear-gradient(to right, #0086FF, #FFA03C);
  /* Gradient for the border */
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
}

.boxedTitle p {
  font-size: 30px;
  color: white;
  font-family: var(--main-font);
  border-radius: 50px;
}

.gamerImgText p {
  color: var(--White, #FFF);
  text-align: left;
  /* Ensure the paragraph fills the container */
}

.heading4 {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.heading2 {
  /* Heading 2 */
  font-family: var(--custom-font);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gamerImgText p {
  color: var(--White, #FFF);
  text-align: left;
  width: 100%;
}

.socialLinks {
  position: relative;
  /* Add this */
  z-index: 3;
  /* Ensure this is higher than overlapping elements */
  display: flex;
  justify-content: space-between;
  margin-top: -43.2px;
  /* -4vh of 1080px */
  /* If you still need the negative margin */
  padding-top: 0;
  gap: 1vw;
  pointer-events: auto;

}

.socialLinks p {
  align-self: stretch;
  place-content: center;
  text-align: center;
}

.socialLinks a {
  z-index: 3;
  cursor: pointer;
}

.RoadMap {
  align-self: stretch;
  padding: var(--paddingSide, 0 8vw);
  width: 65vw;
  align-self: center;
}

.RoadMap h1 {
  text-align: center;
  margin-top: 75.6px;
  /* 7vh of 1080px */
  margin-bottom: 0;
  padding-bottom: 2vh;
}

.RoadMap span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.aboutUsSpacer {
  height: 540px;
  /* 50% of 1080px */
}

.RoadMapSpacer {
  height: 864px;
  /* 80% of 1080px */
}

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: var(--paddingSide, 0 8vw);

}

.partnersSpacer {
  height: 388.8px;
  /* 36% of 1080px */
}












.circle-container {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(135deg, #0086FF 29.83%, #FFA03C 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  aspect-ratio: 1/1;
}

.description {
  margin-top: 10px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
}

/* Container for the line and arrow */
.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1000px;
  /* Adjust width to match the scene's scale */
  height: 20px;
  /* Adjust as needed */
  position: relative;
  padding-bottom: 100px;
}

/* Gradient line */
.gradient-line {
  flex-grow: 1;
  height: 5px;
  background: linear-gradient(135deg, #0086FF 29.83%, #FFA03C 100%);
}

/* Arrow pointing to the right */
.arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #FFA03C;
  margin-left: -5px;
  /* Overlap the arrow slightly with the line */
}

.FooterContainer {
  height: 648px;
}

/* Canvas Layer */
canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  transform-origin: center;
}

/* End Pager */
.endpager {
  padding: 0 10vw 4vh 10vw;
}

/* Lenis Styles */
html.lenis body {
  height: auto;
}

.label {
  position: relative;
  display: inline-block;
  width: auto;
  min-width: 10px;
  /* Starting size */
  height: auto;
  padding: 10px;
  background: #80808000;
  backdrop-filter: blur(0px);
  border-radius: 10%;
  transition: all 0.3s ease;
  cursor: pointer;
  opacity: 1;
  text-align: center;
}

/* Hidden text elements */
.label h1,
.label h2,
.label p {
  opacity: 1;
  margin: 0;
  padding: 0;
  color: #fff;
  transition: opacity 0.3s ease;
}
.label p {
  opacity: 0;
}
/* Expanded label on hover */
.label:hover, .hovered {
  padding: 20px;
  /* Adjust this for spacing around the text */
  border-radius: 15px;
  opacity: 1;
  background: #80808080;
  backdrop-filter: blur(8px);
}

/* Show text on hover */
.label:hover h1,
.label:hover h2,
.label:hover p,
.hovered p {
  opacity: 1;
}


.portrait-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.portrait-circle {
  width: 200px;
  /* Adjust as needed */
  height: 200px;
  /* Adjust as needed */
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

.mission p {
  width: 80%;
}

.vision p {
  width: 97%;
}


#loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;
}

#loaderText {
  color: white;
  font-size: 2em;
  margin-bottom: 20px;
}

#loaderSpinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.vision {
  margin: 0;

  width: 906px;
  height: 472px;

  border-radius: 36px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  background: var(--Custom-bg0, linear-gradient(135deg, rgba(10, 15, 41, 0.25) 0%, rgba(10, 15, 41, 0.13) 40%, rgba(159, 1, 173, 0.13) 72%, rgba(159, 1, 173, 0.25) 100%), #030511);
  box-shadow: 0px -4px 24px 0px rgba(10, 15, 41, 0.50) inset, -5px 5px 24px 0px rgba(255, 255, 255, 0.05);

  overflow: hidden;
  position: relative;
}

.mission {
  margin: 0;

  width: 442px;
  height: 472px;

  border-radius: 36px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  background: var(--Custom-bg0, linear-gradient(135deg, rgba(10, 15, 41, 0.25) 0%, rgba(10, 15, 41, 0.13) 40%, rgba(159, 1, 173, 0.13) 72%, rgba(159, 1, 173, 0.25) 100%), #030511);
  box-shadow: 0px -4px 24px 0px rgba(10, 15, 41, 0.50) inset, -5px 5px 24px 0px rgba(255, 255, 255, 0.05);

  overflow: hidden;
  position: relative;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



@media (max-width: 1600px) {
  #top-section {
    padding: 0 9vw;
    position: absolute;
    width: 100%;
    padding-top: calc(80px + 2vh);

    transition: transform 0.5s ease-in-out;
  }

}

.spiral-frame {
  content: url(../assets/img/Spiral_Frame.webp)
}

.cube-frame {
  content: url(../assets/img/Cube_frame.webp)
}

@media (max-width: 800px) {
  .vision{
    height: 620px;
    width: 89vw;
  }

  .vision img{
    right: 0px;
    bottom: -128px;
  }

  .two-thirds{
    flex: none;
  }

  .mission{
    height: 500px !important;
    width: 89vw;
  }

  .mission img{
    right: 0px;
    bottom: -128px;
  }

  .one-third{
    flex: none;
  }

  .TopContainer h1 {
    font-size: 13vw;
  }

  .TopContainer h2 {
    font-size: 6vw;
  }

  .TopContainer p {
    font-size: 6vw;
    width: 70vw;
  }

  .bottomSectionContainer {
    width: 90vw;
    max-width: 650px;
    height: auto;
  }

  .gamerPlatform {
    width: 100%;
  }

  .gamerPlatform span h1 {
    width: 100%;
  }

  .gamerPlatform span p {
    width: 100%;
  }

  .gamerPlatform span {
    flex-direction: column;
  }

  .gamerPlatformDetails {
    flex-direction: column;
  }

  .gamerPlatform h2 {
    font-size: 5vw;
  }

  .heading1 {
    font-size: 10vw;
  }

  .heading4 {
    font-size: 5vw;
  }

  .paragraph1 {
    font-size: 5vw;
  }
  .gamerImgText {
    height: auto;
    padding: 0 !important;
    width: 50vw;
    left: 40%;
    margin: 7% 0;
  }

  .gamerImgText h2 {
    padding: 20px 30px;
  }

  .gamerImgText p {
    font-size: min(6vw, 28px);
    padding: 0;
    width: 70vw;
  }

  .aboutUs {
    height: 600px;
  }

  ;

  .aboutUs span {
    flex-direction: column;
    width: 100%;
  }

  .aboutUs p {
    font-size: 6vw;
    width: 70vw;
  }


  #roadmapTitles {
    opacity: 0;
  }

  .blurryRoundedBox {
    height: auto;
    width: 72%;
  }

  .spiral-frame {
    content: url(../assets/img/Spiral_frame_mobile.webp)
  }

  .cube-frame {
    content: url(../assets/img/Cube_frame_mobile.webp)
  }

  .socialLinks p {
    width: 90vw;
  }

  .vision {
    margin: 0;
    height: 610px; /* 75% of 1080px */

  }

  .mission {
    margin: 0;
    height: 510px; /* 75% of 1080px */

  }

  .RoadMap h1 {
    font-size: 11vw;
  }

  .RoadMap h2 {
    padding: 15px 30px;
  }

  .RoadMap span {
    flex-direction: column;
    padding-top: 10px;
    padding-right: 223px;
    gap: 830px;
  }

  .aboutUsSpacer {
    height: 1324px;
  }

  .RoadMapSpacer {
    height: 313px;
  }

  .partners h1 {
    font-size: 10vw;
  }

  .FooterContainer {
    height: 800px;
  }

  .BottomButton {
    opacity: 0;
  }

  .line-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 950px;
    /* Adjust height to match the scene's scale */
    width: 20px;
    /* Adjust as needed */
    position: relative;
    flex-direction: column;
    /* Set to column for vertical alignment */
  }

  .circle-container {
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: row-reverse;
  }

  /* Gradient line */
  .gradient-line {
    flex-grow: 1;
    width: 5px;
    height: 1000px;
    background: linear-gradient(180deg, #0086FF 29.83%, #FFA03C 100%);
    /* Adjusted to vertical gradient */

  }

  .description {
    padding-right: 36px;
  }

  /* Arrow pointing down */
  .arrow {
    border-bottom: 15px solid #ffa03c;
    border-left: 10px solid #0000;
    border-right: 10px solid #0000;
    width: 0;
    height: 0;
    margin-left: 0px;
    /* Overlap the arrow slightly with the line */
  }

  .partnersSpacer {
    height: 830px;
  }
}

@media (max-width: 380px) {
  .aboutUsSpacer {
    height: 1144px;
  }

  .RoadMapSpacer {
    height: 600px;
  }
}